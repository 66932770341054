const priceLists = {
  school: [
    {
      teachers: 1,
      includedStudents: 150,
      discount: 0,
      prices: {
        NOK: 1200,
        SEK: 1200,
        EUR: 120,
        USD: 149,
      },
    },
    {
      teachers: 2,
      includedStudents: 300,
      discount: 0,
      prices: {
        NOK: 2400,
        SEK: 2400,
        EUR: 240,
        USD: 297,
      },
    },
    {
      teachers: 3,
      includedStudents: 450,
      discount: 0,
      prices: {
        NOK: 3600,
        SEK: 3600,
        EUR: 360,
        USD: 446,
      },
    },
    {
      teachers: 4,
      includedStudents: 600,
      discount: 0,
      prices: {
        NOK: 4800,
        SEK: 4800,
        EUR: 480,
        USD: 594,
      },
    },
    {
      teachers: 5,
      includedStudents: 750,
      discount: 0.02,
      prices: {
        NOK: 5880,
        SEK: 5880,
        EUR: 588,
        USD: 728,
      },
    },
    {
      teachers: 10,
      includedStudents: 1500,
      discount: 0.05,
      prices: {
        NOK: 11400,
        SEK: 11400,
        EUR: 1140,
        USD: 1411,
      },
    },
    {
      teachers: 30,
      includedStudents: 4500,
      discount: 0.07,
      prices: {
        NOK: 33480,
        SEK: 33480,
        EUR: 3348,
        USD: 4143,
      },
    },
    {
      teachers: 60,
      includedStudents: 9000,
      discount: 0.1,
      prices: {
        NOK: 64800,
        SEK: 64800,
        EUR: 6480,
        USD: 8019,
      },
    },
    {
      teachers: 100,
      includedStudents: 15000,
      discount: 0.12,
      prices: {
        NOK: 105600,
        SEK: 105600,
        EUR: 10560,
        USD: 13068,
      },
    },
  ],
  otherEdu: [
    {
      teachers: 1,
      includedStudents: 150,
      discount: 0,
      prices: {
        NOK: 5500,
        SEK: 5445,
        EUR: 545,
        USD: 681,
      },
    },
    {
      teachers: 2,
      includedStudents: 300,
      discount: 0,
      prices: {
        NOK: 11000,
        SEK: 10890,
        EUR: 1089,
        USD: 1361,
      },
    },
    {
      teachers: 3,
      includedStudents: 450,
      discount: 0,
      prices: {
        NOK: 16500,
        SEK: 16335,
        EUR: 1634,
        USD: 2042,
      },
    },
    {
      teachers: 4,
      includedStudents: 600,
      discount: 0,
      prices: {
        NOK: 22000,
        SEK: 21780,
        EUR: 2178,
        USD: 2723,
      },
    },
    {
      teachers: 5,
      includedStudents: 750,
      discount: 0.02,
      prices: {
        NOK: 26950,
        SEK: 26681,
        EUR: 2668,
        USD: 3335,
      },
    },
    {
      teachers: 10,
      includedStudents: 1500,
      discount: 0.05,
      prices: {
        NOK: 52250,
        SEK: 51728,
        EUR: 5173,
        USD: 6466,
      },
    },
    {
      teachers: 30,
      includedStudents: 4500,
      discount: 0.07,
      prices: {
        NOK: 153450,
        SEK: 151916,
        EUR: 15192,
        USD: 18989,
      },
    },
    {
      teachers: 60,
      includedStudents: 9000,
      discount: 0.1,
      prices: {
        NOK: 297000,
        SEK: 294030,
        EUR: 29403,
        USD: 36754,
      },
    },
    {
      teachers: 100,
      includedStudents: 15000,
      discount: 0.12,
      prices: {
        NOK: 484000,
        SEK: 479160,
        EUR: 47916,
        USD: 59895,
      },
    },
  ],
  enterprise: [
    {
      teachers: 1,
      includedStudents: 150,
      discount: 0,
      prices: {
        NOK: 11000,
        SEK: 10890,
        EUR: 1089,
        USD: 1361,
      },
    },
    {
      teachers: 2,
      includedStudents: 300,
      discount: 0,
      prices: {
        NOK: 22000,
        SEK: 21780,
        EUR: 2178,
        USD: 2723,
      },
    },
    {
      teachers: 3,
      includedStudents: 450,
      discount: 0,
      prices: {
        NOK: 33000,
        SEK: 32670,
        EUR: 3267,
        USD: 4084,
      },
    },
    {
      teachers: 4,
      includedStudents: 600,
      discount: 0,
      prices: {
        NOK: 44000,
        SEK: 43560,
        EUR: 4356,
        USD: 5445,
      },
    },
    {
      teachers: 5,
      includedStudents: 750,
      discount: 0.02,
      prices: {
        NOK: 53900,
        SEK: 53361,
        EUR: 5336,
        USD: 6670,
      },
    },
    {
      teachers: 10,
      includedStudents: 1500,
      discount: 0.05,
      prices: {
        NOK: 104500,
        SEK: 103455,
        EUR: 10346,
        USD: 12932,
      },
    },
    {
      teachers: 30,
      includedStudents: 4500,
      discount: 0.07,
      prices: {
        NOK: 306900,
        SEK: 303831,
        EUR: 30383,
        USD: 37979,
      },
    },
    {
      teachers: 60,
      includedStudents: 9000,
      discount: 0.1,
      prices: {
        NOK: 594000,
        SEK: 588060,
        EUR: 58806,
        USD: 73508,
      },
    },
    {
      teachers: 100,
      includedStudents: 15000,
      discount: 0.12,
      prices: {
        NOK: 968000,
        SEK: 958320,
        EUR: 95832,
        USD: 119790,
      },
    },
  ],
};

export default priceLists;
