import { Fragment, createContext } from 'preact';
import { useContext } from 'preact/hooks';

const translations = {
  sv: {
    noTeachersLabel: 'Antal lärare',
    noPupilsLabel: 'Antal elever',
    costPerYear: 'Kostnad per år',
    currency: 'Valuta',
    schools: 'Grundskola',
    otherEdu: 'Övrig utbildning',
    enterprise: 'Företag',
  },
  en: {
    noTeachersLabel: 'Number of teachers',
    noPupilsLabel: 'Number of students',
    costPerYear: 'Cost per year',
    currency: 'Currency',
    schools: 'School',
    otherEdu: 'Higher Education',
    enterprise: 'Business',
  },
  nb: {
    noTeachersLabel: 'Antall lærere',
    noPupilsLabel: 'Antall studenter',
    costPerYear: 'Kostnad per år',
    currency: 'Valuta',
    schools: 'Skole',
    otherEdu: 'Annen opplæring',
    enterprise: 'Bedrifter',
  },
};

translations['nb.ent'] = {
  ...translations.nb,
  noTeachersLabel: 'Antall ledere',
  noPupilsLabel: 'Antall deltakere',
};

translations['sv.ent'] = {
  ...translations.sv,
  noTeachersLabel: 'Antal ledare',
  noPupilsLabel: 'Antal deltagare',
};

translations['en.ent'] = {
  ...translations.en,
  noTeachersLabel: 'Number of leaders',
  noPupilsLabel: 'Number of participants',
};

const TranslationContext = createContext({ lang: 'sv', enterprise: false });

export const Translate = (props) => {
  const ctx = useContext(TranslationContext);
  let lang = ctx.lang;
  if (ctx.enterprise) {
    lang = lang+'.ent';
  }
  if (translations[lang][props.t]) {
    return <Fragment>{translations[lang][props.t]}</Fragment>;
  } else {
    return <Fragment>{props.t}</Fragment>;
  }
};

const TranslationProvider = (props) => {
  const ctx = useContext(TranslationContext);
  ctx.lang = props.lang;
  ctx.enterprise = props.isEnterprise || false;
  return props.children;
};

export default TranslationProvider;
