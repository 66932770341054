import { useState, useEffect } from 'preact/hooks';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import { Slider } from '@material-ui/core';
import priceList from '../data/pricelist';
import TranslationProvider, { Translate } from './i18n';
import { Fragment } from 'preact';

const currencies = [
  { value: 'SEK', label: 'SEK' },
  { value: 'NOK', label: 'NOK' },
  { value: 'EUR', label: 'EUR' },
  { value: 'USD', label: 'USD' },
];

const muiTheme = createTheme({
  overrides: {
    MuiSlider: {
      thumb: {
        color: '#44b177',
      },
      track: {
        color: '#fdc527',
      },
      rail: {
        color: 'black',
      },
    },
  },
});

const buttonStyle = {
  borderRadius: '100px',
  border: 'none',
  display: 'inline-block',
  cursor: 'pointer',
  fontFamily: '"Roboto",Sans-serif',
  fontSize: '16px',
  padding: '5px 10px',
  position: 'relative',
  textDecoration: 'none !important',
  marginTop: '10px',
  marginLeft: '5px',
};

const summaLabel = {
  color: '#ffffff',
  fontWeight: 800,
  width: '230px',
  fontSize: '18px',
  display: 'inline-block',
  paddingTop: '4px',
};

const summaValue = {
  color: '#ffffff',
  fontWeight: 400,
  width: '75px',
  fontSize: '18px',
  textAlign: 'right',
  display: 'inline-block',
};

const App = () => {
  const initialTeachers = 2;
  const [orgType, setOrgType] = useState('init');
  const [teacherCount, setTeacherCount] = useState(initialTeachers);
  const [studentCount, setStudentCount] = useState();
  const [costPerYear, setCostPerYear] = useState();
  const [currency, setCurrency] = useState('SEK');
  const [lang, setLang] = useState('sv');

  useEffect(() => {
    if (window.location.search.length > 0) {
      let params = new URLSearchParams(window.location.search);
      if (params.has('lang')) {
        let lang = params.get('lang');
        if (['sv', 'en', 'nb'].includes(lang)) {
          setLang(lang);
        } else {
          setLang('sv');
        }
      }
      if (orgType == 'init') {
        if (params.has('mode')) {
          let mode = params.get('mode');
          if (!['school', 'otherEdu', 'enterprise'].includes(mode)) {
            mode = 'school';
          }
          setOrgType(mode);
        } else {
          setOrgType('school');
        }
      }
    } else {
      if (orgType == 'init') {
        setOrgType('school');
      }
    }
    if (orgType !== 'init') {
      let tier = priceList[orgType].find((e) => {
        return e.teachers >= teacherCount;
      });
      const newStudentCount =
        (tier.includedStudents / tier.teachers) * teacherCount;
      setStudentCount(newStudentCount);
      setCostPerYear(
        (tier.prices[currency] / tier.includedStudents) * newStudentCount
      );
    }
  }, [orgType, teacherCount, currency]);

  useEffect(() => {
    switch (lang) {
      case 'nb':
        setCurrency('NOK');
        break;
      case 'sv':
        setCurrency('SEK');
        break;
      case 'en':
        setCurrency('EUR');
        break;
    }
  }, [lang]);

  const buildButtonRow = () => {
    return (
      <div id="buttonRow" style={{ display: 'flex', justifyContent: 'center' }}>
        <button
          style={{
            ...buttonStyle,
            background: '#ab7000',
            color: orgType == 'school' ? '#ffffff' : '#E0E0E0',
            fontWeight: orgType == 'school' ? 800 : 400,
          }}
          onClick={() => {
            setOrgType('school');
          }}
        >
          <Translate t={'schools'} />
        </button>
        <button
          style={{
            ...buttonStyle,
            background: '#0D4827',
            color: orgType == 'otherEdu' ? '#ffffff' : '#E0E0E0',
            fontWeight: orgType == 'otherEdu' ? 800 : 400,
          }}
          onClick={() => {
            setOrgType('otherEdu');
          }}
        >
          <Translate t={'otherEdu'} />
        </button>
        <button
          style={{
            ...buttonStyle,
            background: '#385E7E',
            color: orgType == 'enterprise' ? '#ffffff' : '#E0E0E0',
            fontWeight: orgType == 'enterprise' ? 800 : 400,
          }}
          onClick={() => {
            setOrgType('enterprise');
          }}
        >
          <Translate t={'enterprise'} />
        </button>
      </div>
    );
  };
  if (orgType === 'init') {
    return <Fragment></Fragment>;
  }
  return (
    <TranslationProvider isEnterprise={orgType === 'enterprise'} lang={lang}>
      <div id="app" style={{ width: '400px' }}>
        {buildButtonRow()}
        <ThemeProvider theme={muiTheme}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '60px',
            }}
          >
            <Slider
              style={{ width: '300px', margin: '0 auto' }}
              valueLabelDisplay="on"
              defaultValue={initialTeachers}
              aria-labelledby="continuous-slider"
              min={1}
              step={1}
              max={50}
              onChange={(e, teachers) => {
                if (teachers == teacherCount) return;
                setTeacherCount(teachers);
              }}
            />
          </div>
          <div id="summa" style={{ paddingLeft: '50px' }}>
            <div>
              <span style={summaLabel}>
                <Translate t={'noTeachersLabel'} />
              </span>
              <span style={summaValue}>{teacherCount}</span>
            </div>
            <div>
              <span style={summaLabel}>
                <Translate t={'noPupilsLabel'} />
              </span>
              <span style={summaValue}>{studentCount}</span>
            </div>
            <div>
              <span style={summaLabel}>
                <Translate t={'costPerYear'} />
              </span>
              <span style={summaValue}>{~~costPerYear} </span>
            </div>
            <div>
              <span style={summaLabel}>
                <Translate t={'currency'} />
              </span>
              <span style={summaValue}>
                <select
                  style={{
                    color: 'white',
                    backgroundColor: 'black',
                    border: 'none',
                    fontSize: '18px',
                    outline: 'none',
                  }}
                  onChange={(e) => {
                    setCurrency(
                      currencies[e.target.options.selectedIndex].value
                    );
                  }}
                >
                  {currencies.map((c) => {
                    if (currency == c.value) {
                      return (
                        <option value={c.value} selected="selected">
                          {c.label}
                        </option>
                      );
                    } else {
                      return <option value={c.value}>{c.label}</option>;
                    }
                  })}
                </select>
              </span>
            </div>
          </div>
        </ThemeProvider>
      </div>
    </TranslationProvider>
  );
};

export default App;
